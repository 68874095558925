import './app.css';
import Navbar from './parts/navbar/Navbar';
import { useState } from 'react';
import languageFile from './other/languageFile'
import UnderConstruction from './screens/other/Under-construction';

function App() {
    const [language, setLanguage] = useState('en');

    function changeLanguage() {
        if (language === 'en') {
            setLanguage('ar');
        }
        else if (language === 'ar') {
            setLanguage('en');
        }
    }

    let text;
    if (language === 'en') {
        text = languageFile.en;
    }
    else if (language === 'ar') {
        text = languageFile.ar;
    }



    return (
        <>
            <Navbar changeLanguage={changeLanguage} text={text.navbar} language={language}/>
            <div id="main-div">
                <UnderConstruction text={text.main} />
            </div>
        </>
    );
}

export default App;