import React from 'react'
import './under-construction.css'


function UnderConstruction(props) {

    let text = props.text

    return (
        <div id="underConstruction">
            <h1>{text.header}</h1>
            <h1>{text.secondHeader}</h1>
            <div className="contact">
                <h2 className="Dev">{text.dev}</h2>
                <h2>{text.contacts.phone}</h2>
                <a href="tel:+9647716493550">+964 771 649 3550</a>
                <h2>{text.contacts.email}</h2>
                <a href="mailto:jaffer@iqarati.app">jaffer@iqarati.app</a>
                <h2>{text.contacts.website}</h2>
                <a href="https://lychas.com">Lychas.com</a>
            </div>
        </div>
    )
}

export default UnderConstruction