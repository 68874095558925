let languageFile = {
    en: {
        navbar: {
            logo: 'Iqarati Company',
            language: "English"
        },
        main: {
            header: 'Iqarati is under construction',
            secondHeader: 'Coming Soon',
            dev: "Lead Developer: Jaffer Mustafa",
            contacts: {
                phone: "My Phone Number",
                github: "My Github",
                email: "My E-mail",
                website: "My Website"
            }
        }
    },
    ar: {
        navbar: {
            logo: 'شركة عقارتي',
            language: "عربي"
        },
        main: {
            header: 'موقع شركة عقارتي قيد الانشاء',
            secondHeader: 'قريبا',
            dev: "المبرمج الرئيسي: جعفر مصطفى",
            contacts: {
                phone: "رقم هاتفي",
                github: "Github حسابي على",
                email: "بريدي الالكتروني",
                website: "موقعي الشخصي"
            }

        }
    }
}


export default languageFile;