import './navbar.css'

function Navbar(props) {
    let text = props.text

    return (
        <div id="navbar" >
            <div className='logo'>
                <img src={require('../../../images/logo.png')} alt="logo" />
                <h1>{text.logo}</h1>
            </div>
            <div className='buttonWrapper'>
                <label className="switch">
                    <input type="checkbox"/>
                    <span className="slider" onClick={() => {props.changeLanguage()}}>
                        <div className="text">
                            {text.language}
                        </div>
                    </span>
                </label>
            </div>
        </div>
    );
}

export default Navbar;